
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { CustomDate } from "@/mixins/custom-date";
import { VueEditor } from "vue2-editor";

@Component({
  components: {
    Loader,
    DetailHeader,
    VueEditor,
  },
})
export default class CheckApplication extends mixins(Navigation, CustomDate) {
  loader = false;

  private async created(): Promise<void> {
    this.loader = true;

    await this.$store
      .dispatch("application/getApplicationByID", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.a-e3"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/applications",
      icon: "mdi-folder-search",
      title: `${this.$tc("Views.a-32")}: ${this.$route.params.id}`,
    });
    this.loader = false;
  }

  private get application() {
    return this.$store.getters["application/getApplication"].application;
  }
}
